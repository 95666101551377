import axios from '../../../../configs/axiosConfig';
import qs from 'qs';
import { history } from '../../../../App';
import * as Actions from '../../../SnackbarMessage/store/actions';

export const AUTHENTICATION = '[AUTH] AUTHENTICATION';
export const AUTHENTICATION_WITH_COOKIE = '[AUTH] AUTHENTICATION WITH COOKIE';
export const AUTHENTICATION_LOGOUT = '[AUTH] AUTHENTICATION LOGOUT';

axios.defaults.withCredentials = true;

/**
 * Logged profile data
 * @returns {object} profile data
 */
async function getProfile() {
  const request = axios.get('/users/me');
  try {
    const response = await request;
    return response.data;
  } catch (error) {
    history.push('/login');
    return {};
  }
}

export function signInWithEmailAndPassword(params) {
  const request = axios.post('/auth/cookie/login', qs.stringify(params), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

  return (dispatch) =>
    request
      .then(async (res) => {
        history.push({
          pathname: '/agent-success-predictions',
        });
        const data = await getProfile();

        dispatch(
          Actions.showMessage({
            message: 'Logged in successfully!',
            variant: 'success',
          })
        );
        dispatch({
          type: AUTHENTICATION,
          payload: data,
        });
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          dispatch(
            Actions.showMessage({
              message: 'Please reload your page and log in again.',
              variant: 'error',
            })
          );
        } else if (error.response.status === 400)
          dispatch(
            Actions.showMessage({
              message: 'Email or password is invalid!',
              variant: 'error',
            })
          );
      });
}

export function signInWithCookie(path) {
  const request = axios.get('/all_users/all');

  return (dispatch) =>
    request
      .then(async (response) => {
        if (response.status === 200) {
          const data = await getProfile();
          if (path) history.push(path);
          return dispatch({
            type: AUTHENTICATION_WITH_COOKIE,
            payload: { isAuthenticated: true, data },
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: AUTHENTICATION_WITH_COOKIE,
          payload: { isAuthenticated: false, data: {} },
        });
      });
}

export function logout() {
  const request = axios.post('/auth/cookie/logout');

  return (dispatch) =>
    request
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          history.push('/login');
          return dispatch({
            type: AUTHENTICATION_LOGOUT,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        history.push('/login');
        return dispatch({
          type: AUTHENTICATION_LOGOUT,
        });
      });
}
