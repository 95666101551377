import axios from '../../../../configs/axiosConfig';
import { history } from '../../../../App';

export const AGENT_SUMMARY = '[AGENT SUMMARY] AGENT SUMMARY';
export const SET_AGENT_SEARCH_TEXT = '[AGENT SUMMARY] SET AGENT SEARCH TEXT';
export const SET_AGENT_YEAR_TEXT = '[AGENT SUMMARY] SET AGENT YEAR TEXT';

axios.defaults.withCredentials = true;

export function getAgentList() {
  const request = axios.get('/agent/summary');

  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: AGENT_SUMMARY,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
        history.push('/login');
      });
}

export function setAgentSearchText(event) {
  return {
    type: SET_AGENT_SEARCH_TEXT,
    searchText: event,
  };
}

export function setAgentYearText(event) {
  return {
    type: SET_AGENT_YEAR_TEXT,
    yearText: event,
  };
}
