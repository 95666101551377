import axios from '../../../../configs/axiosConfig';
import * as Actions from '../../../SnackbarMessage/store/actions';

export const GET_AGENT = '[AGENT DETAILS] GET_AGENT';
export const GET_SUCCESS_POINT_DATA = '[AGENT DETAILS] GET_SUCCESS_POINT_DATA';
export const SET_AGENT_SEARCH_TEXT = '[AGENT SUMMARY] SET AGENT SEARCH TEXT';
export const SET_AGENT_YEAR_TEXT = '[AGENT SUMMARY] SET AGENT YEAR TEXT';

axios.defaults.withCredentials = true;

export function getAgent(agentId) {
  const request = axios.get('/agent/details/' + agentId);

  return (dispatch) => {
    request
      .then((response) => {
        dispatch({
          type: GET_AGENT,
          payload: response.data,
        });
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          dispatch(
            Actions.showMessage({
              message: 'Please reload your page and log in again.',
              variant: 'error',
            })
          );
        } else {
          dispatch(
            Actions.showMessage({
              message: (error && error.message) || 'Something went wrong',
              variant: 'error',
            })
          );
        }
      });
  };
}

export function getSuccessPointDataForAgent(body) {
  const request = axios.post('/agent/successpoint', body);

  return (dispatch) => {
    request
      .then((response) => {
        dispatch({
          type: GET_SUCCESS_POINT_DATA,
          payload: response.data,
        });
      })
      .catch((error) => {
        const indicatorError =
          error.response &&
          error.response.data &&
          Array.isArray(error.response.data.detail) &&
          error.response.data.detail.length > 0 &&
          error.response.data.detail[0];

        if (
          error.response.status === 422 &&
          indicatorError &&
          indicatorError.loc &&
          indicatorError.loc.find((e) => e === 'indicator_ids') &&
          indicatorError.type === 'value_error.list.min_items'
        ) {
          dispatch(
            Actions.showMessage({
              message: 'at least one indicator must be enabled',
              variant: 'error',
            })
          );
        } else if (error?.response?.status === 401) {
          dispatch(
            Actions.showMessage({
              message: 'Please reload your page and log in again.',
              variant: 'error',
            })
          );
        } else {
          dispatch(
            Actions.showMessage({
              message: (error && error.message) || 'Something went wrong',
              variant: 'error',
            })
          );
        }
      });
  };
}

export function setAgentSearchText(event) {
  return {
    type: SET_AGENT_SEARCH_TEXT,
    searchText: event,
  };
}

export function setAgentYearText(event) {
  return {
    type: SET_AGENT_YEAR_TEXT,
    yearText: event,
  };
}
