import React, { useState, useEffect } from 'react';
import './agentDetails.css';
import backIcon from '../../assets/left-arrow.png';
import settingSmallIcon from '../../assets/settings-small.png';
import editIcon from '../../assets/edit.png';
import ProfilePic from '../../assets/businessman-avatar-profile-picture-2.jpg';
import Header from '../Header';
import SideNav from '../SideBar';
import ProgressBar from '../ProgressBar';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Divider,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from '@material-ui/core';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Dialog from '@material-ui/core/Dialog';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { AntSwitch } from '../ToggleButton';
import SplashScreen from '../SplashScreen';
import * as authAction from '../Login/store/actions';
import * as Actions from './store/actions';
import * as AgentActions from '../AgentSummary/store/actions';
import reducer from './store/reducers';
import withReducer from '../../store/withReducer';
import $ from 'jquery';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const settings = {
  slidesToShow: 5,
  infinite: false,
};

const AgentDetails = () => {
  const [activeCard, setActiveCard] = useState();
  const [year_number, setYearNumber] = useState();
  const [activeIndex, setActiveIndex] = useState();
  const [open, setOpen] = useState(false);
  const [fromDate, handleFromDateChange] = useState(new Date());
  const [expectedDate, handleExpectedDateChange] = useState(new Date());
  const [waitAuthcheck, setWaitAuthCheck] = useState(true);

  const agentData = useSelector(
    ({ agentDetails }) => agentDetails.agentDetails.data
  );
  const agentSummary = useSelector(
    ({ agentSummary }) => agentSummary.agentSummary.agentSummary
  );
  const dispatch = useDispatch();

  let history = useHistory();
  const { agentId } = useParams();

  const userData = useSelector(({ login }) => login.authReducer.data);

  if (useParams().length === 0) history.push('/agent-success-predictions');

  useEffect(() => {
    setActiveCard(0);
    setYearNumber(0);
    setActiveIndex(0);
  }, []);

  useEffect(() => {
    // Set first card as active from array
    if (
      agentData &&
      Array.isArray(agentData.successpoints) &&
      agentData.successpoints.length &&
      !agentData.successpoints.find((sp) => sp.sp_id === activeCard)
    ) {
      setActiveCard(agentData.successpoints[0].sp_id);
      setYearNumber(agentData.successpoints[0].years);
    }
  }, [agentData]);

  useEffect(() => {
    if (agentId) {
      dispatch(Actions.getAgent(agentId));
      dispatch(AgentActions.getAgentList());
    }
  }, [agentId, dispatch]);

  const getToggleNewCalculatedData = (indicator_ids) => {
    dispatch(
      Actions.getSuccessPointDataForAgent({
        agent_id: +agentId,
        sp_id: activeCard,
        indicator_ids,
        year_number,
      })
    );
  };

  useEffect(() => {
    if (agentData && agentData.successpoints) {
      let indicator = agentData.successpoints.find(
        (sp) => sp.sp_id === activeCard
      );

      if (indicator && Array.isArray(indicator.indicators)) {
        let indicator_ids = indicator.indicators
          .filter((indicatorData) => indicatorData.is_selected)
          .map((indicatorData) => indicatorData.indicator_id);
        getToggleNewCalculatedData(indicator_ids);
      }
    }
  }, [year_number]);

  useEffect(() => {
    dispatch(authAction.signInWithCookie(history.location.pathname)).then(
      () => {
        setWaitAuthCheck(false);
      }
    );
  }, [dispatch, history.location.pathname]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  /**
   * Toggle Handler
   */
  const handleChange = (event) => {
    let indicator_ids = agentData.successpoints.find(
      (sp) => sp.sp_id === activeCard
    );

    indicator_ids = indicator_ids.indicators
      .filter((indicatorData) =>
        indicatorData.indicator_id === +event.target.name
          ? event.target.checked
          : indicatorData.is_selected
      )
      .map((indicatorData) => indicatorData.indicator_id);
    getToggleNewCalculatedData(indicator_ids);
  };

  const cardChangeHandler = (sp, index) => {
    setActiveCard(sp.sp_id);
    setYearNumber(sp.years);
    setActiveIndex(index);
  };

  //  Show loader till data loads
  if (waitAuthcheck || !agentData) {
    return <SplashScreen />;
  }

  const currentSuccessPoint =
    ((agentData && agentData.successpoints) || []).find(
      (sp) => sp.sp_id === activeCard
    ) || {};

  const currentIndicators =
    (currentSuccessPoint && currentSuccessPoint.indicators) || [];

  const afterChange = (oldIndex, newIndex) => {
    // Check is current selected card hidden?
    try {
      const cardIndex =
        agentData &&
        agentData.successpoints.findIndex((sp) => sp.sp_id === activeCard);
      const cardHidden = $(`[data-index="${cardIndex}"]`).attr('aria-hidden');
      if (cardHidden === 'true') {
        // not visible
        // Get first visible card data-index
        const chosenActiveCard = Array.from($('.slick-active')).find(
          (slide) => {
            return slide.getAttribute('aria-hidden') === 'false';
          }
        );
        if (chosenActiveCard) {
          cardChangeHandler(
            ((agentData && agentData.successpoints) || [])[
              chosenActiveCard.getAttribute('data-index')
            ],
            chosenActiveCard.getAttribute('data-index')
          );
        }
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <>
      <div>
        <Header />
      </div>

      <div>
        <SideNav />
      </div>
      <div className="mainPortion">
        <div className="upperHeader">
          <span className="bottomBorder">
            <span>
              <Link className="tabText" to="/agent-success-predictions">
                Agent Summary
              </Link>
            </span>
          </span>{' '}
          &nbsp;
          <span className="bottomBorder activeTab">
            <span className="tabText">Agent Details</span>
          </span>
          <span>
            <span className="adminPortal">
              <img src={settingSmallIcon} alt="settingIcon" className="icon" />
              <span onClick={() => history.push('/admin-portal/users')}>
                {/* <Link style={{ textDecoration: 'none' }} to="/admin-portal">Admin Portal</Link> */}
                Admin Portal
              </span>
            </span>
            <button className="btn newBtn" onClick={handleClickOpen}>
              ADD SUCCESS POINT
            </button>
          </span>
        </div>

        <Dialog open={open} keepMounted onClose={handleClose} maxWidth="lg">
          <div style={{ padding: '20px' }}>
            <div
              className="flexRow flex-row"
              style={{ justifyContent: 'space-between' }}
            >
              <div className="text4 flexColumn">Success points settings</div>
              <br />
              <div
                className="m-5"
                style={{ cursor: 'pointer' }}
                onClick={handleClose}
              >
                X
              </div>
            </div>

            <div className="flexRow flex-row">
              <div className="flexColumn">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <div className="flexRow flex-row">
                    <div className="flexColumn">
                      <span className="boldText">Name</span>
                      <br />
                      <input
                        type="text"
                        placeholder="Name"
                        className="dialogInput"
                      />
                      <br />
                    </div>
                    <div className="flexColumn">
                      <span className="boldText">Success point amount</span>
                      <br />
                      <OutlinedInput
                        type="text"
                        className="dialogInput monetary-field"
                        placeholder="Success point amount"
                        startAdornment={
                          <InputAdornment position="start">$</InputAdornment>
                        }
                      />
                      <br />
                    </div>
                    <div className="flexColumn">
                      <span className="boldText">From</span>
                      <br />
                      <KeyboardDatePicker
                        format="MM/dd/yyyy"
                        inputVariant="outlined"
                        value={fromDate}
                        onChange={handleFromDateChange}
                        maxDate={new Date()}
                        style={{ width: '170px' }}
                      />
                      {/* <span className="text4">></span> */}
                    </div>

                    <div className="flexColumn">
                      <span className="boldText">Expecting Success by:</span>
                      <br />
                      <KeyboardDatePicker
                        format="MM/dd/yyyy"
                        inputVariant="outlined"
                        value={expectedDate}
                        onChange={handleExpectedDateChange}
                        minDate={fromDate}
                        style={{ width: '170px' }}
                      />
                    </div>
                  </div>
                </MuiPickersUtilsProvider>
                <div>
                  <div className="text4 mb-4">Indicators to show</div>
                  <div className="flexRow flex-row">
                    <div className="flexColumn">
                      <div className="d-flex">
                        <span>
                          <label className="container chk-box">
                            <input type="checkbox" />
                            <span className="checkmark"></span>
                          </label>
                        </span>
                        <span>Total Apps Received</span>
                      </div>
                      <br />
                      <div className="d-flex">
                        <span>
                          <label className="container chk-box">
                            <input type="checkbox" />
                            <span className="checkmark"></span>
                          </label>
                        </span>
                        <span>Apps Submitted</span>
                      </div>
                    </div>
                    <div className="flexColumn">
                      <div className="d-flex">
                        <span>
                          <label className="container chk-box">
                            <input type="checkbox" />
                            <span className="checkmark"></span>
                          </label>
                        </span>
                        <span>Apps Placed</span>
                      </div>
                      <br />
                      <div className="d-flex">
                        <span>
                          <label className="container chk-box">
                            <input type="checkbox" />
                            <span className="checkmark"></span>
                          </label>
                        </span>
                        <span>Consistency</span>
                      </div>
                    </div>
                    <div className="flexColumn">
                      <div className="d-flex">
                        <span>
                          <label className="container chk-box">
                            <input type="checkbox" />
                            <span className="checkmark"></span>
                          </label>
                        </span>
                        <span>Ratio of Received to Submitted</span>
                      </div>
                      <br />
                      <div className="d-flex">
                        <span>
                          <label className="container chk-box">
                            <input type="checkbox" />
                            <span className="checkmark"></span>
                          </label>
                        </span>
                        <span>Ratio of Submitted to Placed</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <Divider orientation="vertical" style={{ margin: '15px' }} />
              <div className="flexColumn">
                <span className="boldText">Based on</span>
                <br />
                <div className="d-flex">
                  <span>
                    <label className="container chk-box">
                      <input type="checkbox" />
                      <span className="checkmark"></span>
                    </label>
                  </span>
                  <span>FYC</span>
                </div>{' '}
                <br />
                <div className="d-flex">
                  <span>
                    <label className="container chk-box">
                      <input type="checkbox" />
                      <span className="checkmark"></span>
                    </label>
                  </span>
                  <span>Lives</span>
                </div>{' '}
                <br />
                <div className="d-flex">
                  <span>
                    <label className="container chk-box">
                      <input type="checkbox" />
                      <span className="checkmark"></span>
                    </label>
                  </span>
                  <span>Protection</span>
                </div>{' '}
                <br />
                <div className="d-flex">
                  <span>
                    <label className="container chk-box">
                      <input type="checkbox" />
                      <span className="checkmark"></span>
                    </label>
                  </span>
                  <span>Premium</span>
                </div>{' '}
                <br />
              </div>
            </div>
            <Divider className="my-3" />
            <div className="d-flex justify-content-between">
              <div className="d-flex align-center">
                <span className="boldText">Created By:</span>{' '}
                <span className="text4 mx-2">
                  {userData && userData.first_name}{' '}
                  {userData && userData.last_name}:
                </span>
                {new Date().getDate() +
                  '/' +
                  (new Date().getMonth() + 1) +
                  '/' +
                  new Date().getFullYear()}
              </div>

              <div className="d-flex">
                <button
                  className="btn defaultBtn"
                  style={{ margin: '0' }}
                  onClick={handleClose}
                >
                  CLOSE
                </button>
                {/* <span>CLOSE</span> */}
                <button
                  className="btn"
                  style={{ margin: '0' }}
                  onClick={handleClose}
                >
                  SAVE
                </button>
              </div>
            </div>
          </div>
        </Dialog>

        <div className="agentDetails">
          <div style={{ 'margin-bottom': '10px' }}>
            <span className="heading">
              <span className="backArrow">
                <Link to="/agent-success-predictions">
                  <img src={backIcon} alt="backArrowIcon" />
                </Link>
              </span>
              {/* <FontAwesomeIcon className="backArrow" icon={faArrowLeft} /> */}
              <span style={{ 'margin-top': '-10' }}>
                Agent Details / {agentData && agentData.agent_name}
              </span>
            </span>
            <span className="filter">
              {/* <span class="search-container">
                                <input className="searchBox" id="search-bar" type="text" placeholder="Search agents" />
                                <FontAwesomeIcon className="search-icon" style={{ 'color': '#64bb46' }} icon={faSearch} />
                            </span> */}
              <FormControl className="search-container">
                <Autocomplete
                  disabled={
                    !Array.isArray(agentSummary) || !agentSummary.length
                  }
                  id="combo-box-demo"
                  options={(agentSummary || []).filter(
                    (agent) => !!agent.agent_id
                  )}
                  getOptionLabel={(option) => option && option.agent_name}
                  style={{ width: 300 }}
                  onChange={(e, agent) => {
                    if (agent && agent.agent_id) {
                      // Redirect to user's page for data
                      history.push({
                        pathname: `/agent-details/${agent.agent_id}`,
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search agents"
                      size="small"
                      variant="outlined"
                    />
                  )}
                />
              </FormControl>
            </span>
          </div>
          <br />
          <Divider
            style={{ width: '88%', 'margin-left': '5em' }}
            variant="middle"
          />
          <br />

          <div className="detailsPortion">
            <div className="pointsCards">
              <div className="d-flex justify-content-between">
                <span className="text4">Success Points</span>
                {/* <span className="orderBy">
                  Filter By:&nbsp;
                  <img src={downArrowIcon} />
                  &nbsp;&nbsp;&nbsp; Sort By:&nbsp;
                  <img src={downArrowIcon} />
                </span> */}
              </div>
              <br />
              <div className="pointsPortation">
                <Slider
                  {...settings}
                  afterChange={afterChange}
                  className={'sp-slider'}
                >
                  {((agentData && agentData.successpoints) || []).map(
                    (sp, index) => {
                      return (
                        <div className="card-container">
                          <Card
                            className={`sp-card ${
                              activeCard === sp.sp_id ? 'activeCard' : ''
                            } pointCard d-flex flex-column`}
                            onClick={() => cardChangeHandler(sp, index)}
                            variant="outlined"
                            title={sp.sp_name}
                          >
                            {activeCard === sp.sp_id ? (
                              <img
                              // src={editIcon}
                              // alt="settingIcon"
                              // className="editIcon"
                              />
                            ) : (
                              <div style={{ 'padding-top': '1.7em' }} />
                            )}
                            <CardContent
                              className="d-flex flex-column flex-1"
                              style={{ 'padding-bottom': '16px' }}
                            >
                              {/* <Typography
                            color="textSecondary"
                            gutterBottom
                          ></Typography> */}
                              <div className="d-flex flex-1 flex-column mb-3">
                                <Typography
                                  variant="h5"
                                  component="h2"
                                  style={{ fontSize: '1em', minHeight: '42px' }}
                                >
                                  <center className="text-line-truncate">
                                    {sp.sp_name}
                                  </center>
                                </Typography>
                              </div>
                              {/* <Typography color="textSecondary"></Typography> */}
                              {/* <Typography variant="body2" component="p"> */}
                              <div className="flex-0">
                                <ProgressBar
                                  progress={Math.round(
                                    sp.likelihood.toFixed(2)
                                  )}
                                />
                              </div>
                              <div className="d-flex justify-content-between flex-0">
                                <small>Likelihood</small>
                                <small className="boldText">
                                  {Math.round(sp.likelihood.toFixed(2))}%
                                </small>
                              </div>
                              <div className="flex-0">
                                <ProgressBar
                                  progress={Math.round(sp.score.toFixed(2))}
                                />
                              </div>
                              <div className="d-flex justify-content-between flex-0">
                                <small>Comparison</small>
                                <small className="boldText">
                                  {Math.round(sp.score.toFixed(2))}%
                                </small>
                              </div>
                            </CardContent>
                          </Card>
                          {activeCard === sp.sp_id && (
                            <div className="arrow-up arrow-up-relative"></div>
                          )}
                        </div>
                      );
                    }
                  )}
                </Slider>
              </div>
              <div>
                <Card className="indicator-section">
                  <CardContent>
                    <div className="indicator-card-header">
                      <div className="d-flex flex-column">
                        <p>Expecting success</p>
                        <span className="calendar">
                          <span class="year-container">
                            <FormControl>
                              <TextField
                                value={year_number}
                                type="number"
                                label="Year"
                                name="year_number"
                                variant="outlined"
                                size="small"
                                onChange={(ev) =>
                                  setYearNumber(+ev.target.value)
                                }
                              />
                            </FormControl>
                          </span>
                        </span>
                      </div>
                      <div className="d-flex">
                        {/* <div className="d-flex align-center mr-4">
                          <span className="fr boldText mr-5">
                            Success Point Average
                          </span>
                          74/100
                        </div> */}
                        <img
                        // src={editIcon}
                        // alt="settingIcon"
                        // className="detailsEditIcon"
                        />
                      </div>
                    </div>
                    <Divider />
                    <br />
                    <span className="text4">Indicators</span>
                    <br />
                    <br />
                    <div className="flexRow indicator_container">
                      {currentIndicators.map((spi) => {
                        return (
                          <div className="rowIndicators">
                            <div className="flexColumn">
                              <div className="flexRow align-item-center">
                                <AntSwitch
                                  checked={spi.is_selected}
                                  onChange={(e) => handleChange(e)}
                                  name={spi.indicator_id}
                                />
                                <span>
                                  {/* <span className="boldText md-text indicator-wight">
                                    {spi.indicator_weight || spi.weight}
                                  </span> */}
                                  <span className="indicator-name">
                                    {spi.indicator_name}
                                  </span>
                                </span>
                              </div>
                              <br />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </CardContent>
                </Card>
                <br />
              </div>
            </div>

            <div className="userCard">
              <Card className="card m-0">
                <CardMedia
                  style={{
                    'border-radius': '100%',
                    height: '140px',
                    width: '140px',
                    margin: '0 auto',
                  }}
                  component="img"
                  image={ProfilePic}
                  title="Profile Pic"
                />
                <CardContent>
                  <div className="userName">{agentData.agent_name}</div>
                  <br />
                  <Divider />
                  <br />
                  <div className="justify-content-between align-center d-flex sm-text boldText">
                    Joined{' '}
                    {new Date(agentData.hire_date).toLocaleDateString('en-US', {
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric',
                    })}
                    <span className="monthBadge">
                      {agentData.months_in_business} Months
                    </span>
                  </div>
                  <br />
                  <Divider />
                  <div className="m-5 justify-center text-center text4">
                    Business
                  </div>
                  <div className="businessData">
                    <span className="fl">FYC</span>{' '}
                    <span className="fr">{agentData.fyc.toLocaleString()}</span>
                    <br />
                    <span className="fl">Lives</span>{' '}
                    <span className="fr">
                      {agentData.lives.toLocaleString()}
                    </span>
                    <br />
                    <span className="fl">Protection</span>{' '}
                    <span className="fr">
                      ${agentData.protection.toLocaleString()}
                    </span>
                    <br />
                    <span className="fl">Premium</span>{' '}
                    <span className="fr">
                      ${agentData.premiums.toLocaleString()}
                    </span>
                    <br />
                  </div>
                  <br />
                  {/* <div>
                    <div className="text-center sm-text boldText">
                      Similer Agents Success Average
                    </div>
                    <br />
                    <span className="fl">
                      <AvatarGroup max={3}>
                        <Avatar>AB</Avatar>
                        <Avatar>GB</Avatar>
                        <Avatar>TB</Avatar>
                      </AvatarGroup>
                    </span>
                    <span className="fr">
                      <div
                        style={{
                          color: '#64bb46',
                          'font-size': '11px',
                          'text-align': 'center',
                        }}
                      >
                        Average
                      </div>
                      <div style={{ 'font-size': '1.5em' }}>
                        <span className="boldText">83</span>/100
                      </div>
                    </span>
                  </div> */}
                </CardContent>
              </Card>
            </div>
          </div>
          <Link
            className="btn view-more-btn td-none"
            to={'/row-data/' + agentId}
          >
            View Details
          </Link>
          <br />
          <br />
          {/* <button className="btn justify-center align-center">View Details</button><br /><br /> */}
        </div>
      </div>
    </>
  );
};

export default withReducer('agentDetails', reducer)(AgentDetails);
