import _ from 'lodash';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as Actions from './store/actions';
import AgentSummaryTableHead from './AgentSummaryTableHead';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import ProgressBar from '../ProgressBar';
import './agentSummary.css';
import { history } from '../../App';

function AgentSummaryTable(props) {
  const dispatch = useDispatch();
  const agentSummary = useSelector(
    ({ agentSummary }) => agentSummary.agentSummary.agentSummary
  );
  const searchText = useSelector(
    ({ agentSummary }) => agentSummary.agentSummary.searchText
  );
  const yearText = useSelector(
    ({ agentSummary }) => agentSummary.agentSummary.yearText
  );

  const [selected, setSelected] = useState([]);
  const [data, setData] = useState(agentSummary);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [order, setOrder] = useState({
    direction: 'asc',
    id: null,
  });

  useEffect(() => {
    document.getElementsByTagName('html')[0].classList.add('sticky-overflow');
    return () => {
      document
        .getElementsByTagName('html')[0]
        .classList.remove('sticky-overflow');
    };
  }, []);

  useEffect(() => {
    dispatch(Actions.getAgentList());
  }, [dispatch, props.successPointsList]);

  useEffect(() => {
    if (searchText.length !== 0) {
      setData(
        _.filter(agentSummary, (item) =>
          item.agent_name.toLowerCase().includes(searchText.toLowerCase())
        )
      );
      setPage(0);
    } else if (yearText.length !== 0) {
      setData(
        _.filter(
          agentSummary,
          (item) => item.months_in_business > parseInt(yearText) * 12
        )
      );
    } else {
      setData(agentSummary);
    }
  }, [agentSummary, searchText, yearText]);

  function handleRequestSort(event, property) {
    const id = property;
    let direction = 'desc';

    if (order.id === property && order.direction === 'desc') {
      direction = 'asc';
    }
    setOrder({
      direction,
      id,
    });
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      setSelected(data.map((n) => n.id));
      return;
    }
    setSelected([]);
  }

  function handleClick(item, index) {
    try {
      const agentClicked = data[index];
      history.push('/agent-details/' + agentClicked.agent_id || item.agent_id);

      const matchedUser = agentSummary?.find(
        (a) => a.agent_name === searchText
      );
      if (!matchedUser) {
        dispatch(Actions.setAgentSearchText(''));
      }
    } catch (error) {
      history.push('/agent-details/' + item.agent_id);
    }
  }

  function handleCheck(event, id) {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  }

  function handleChangePage(event, value) {
    setPage(value);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
  }

  if (data.length === 0) {
    return (
      <div className="flex flex-1 text-center justify-center h-full">
        <Typography color="textSecondary" variant="h5">
          No records found for matching filter criteria!
        </Typography>
      </div>
    );
  }

  // Generate Row headers from success point list

  const successPointHeader = [];
  if (
    Array.isArray(props.successPointsList) &&
    props.successPointsList.length
  ) {
    props.successPointsList
      // Filter out disabled filters from admin
      .filter((sp) => sp.is_default)
      .forEach((successPoint) => {
        successPointHeader.push({
          id: `sp-${successPoint.sp_id}`,
          align: 'left',
          disablePadding: false,
          label: successPoint.sp_name,
          sort: true,
          editModal: true,
          successPoint,
        });
      });
  }

  return (
    <div
      className="w-full flex flex-col"
      style={{ marginLeft: '3em', marginRight: '3em' }}
    >
      <Table component={Paper} aria-labelledby="tableTitle">
        <AgentSummaryTableHead
          numSelected={selected.length}
          order={order}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={data.length}
          partialHeader={successPointHeader}
        />

        <TableBody>
          {data.length > 0 &&
            _.orderBy(
              data,
              [
                (o) => {
                  // Check it's success point column id by prefix
                  if (order.id && order.id.includes('sp-')) {
                    let successPointIndex = o.success_points.findIndex(
                      (sP) => sP.sp_id === +order.id.split('sp-')[1]
                    );

                    // No Success point data found in current data
                    if (successPointIndex === -1) {
                      return -1;
                    }
                    return (
                      o.success_points &&
                      o.success_points[successPointIndex || 0].score
                    );
                  }
                  return o[order.id];
                },
              ],
              [order.direction]
            )
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((n, index) => {
                const isSelected = selected.indexOf(n.id) !== -1;
                return (
                  <TableRow
                    style={{ cursor: 'pointer' }}
                    hover
                    role="checkbox"
                    aria-checked={isSelected}
                    tabIndex={-1}
                    key={index}
                    selected={isSelected}
                    onClick={(event) => {
                      event.preventDefault();
                      handleClick(n, index);
                    }}
                  >
                    {/* <TableCell className="w-64 text-center" padding="none">
                      <Checkbox
                        checked={isSelected}
                        onClick={(event) => event.stopPropagation()}
                        onChange={(event) => handleCheck(event, n.id)}
                      />
                    </TableCell> */}

                    <TableCell component="th" scope="row">
                      {n.agent_name}
                    </TableCell>

                    <TableCell component="th" scope="row">
                      {n.months_in_business}
                    </TableCell>

                    <TableCell
                      style={{ fontSize: '11px' }}
                      component="th"
                      scope="row"
                    >
                      Likelihood
                      <br />
                      Comparison
                    </TableCell>

                    {successPointHeader.map((spHeader) => {
                      const spData = n.success_points.find(
                        (s) => s.sp_id === spHeader.successPoint.sp_id
                      );

                      if (!spData || !spData.sp_id) {
                        return <></>;
                      }

                      return (
                        <>
                          {/* <div className="agentPoints spacingCardPoints">{spData.likelihood.toFixed(2)}</div> */}
                          <TableCell component="th" scope="row">
                            <div
                              className="successPoints spacingCardPoints"
                              key={spData.sp_id}
                            >
                              <div>
                                {spData.sp_name}
                                <br />
                                <span style={{ 'font-size': '10px' }}>
                                  <span className="bold">
                                    {Math.round(spData.likelihood.toFixed(2))}
                                  </span>
                                  %
                                </span>
                                <ProgressBar
                                  progress={Math.round(
                                    spData.likelihood.toFixed(2)
                                  )}
                                />
                                <span style={{ 'font-size': '10px' }}>
                                  <span className="bold">
                                    {Math.round(spData.score.toFixed(2))}
                                  </span>
                                  %
                                </span>
                              </div>
                            </div>
                          </TableCell>
                        </>
                      );
                    })}

                    {/* <TableCell component="th" scope="row">
										{n.field}
									</TableCell>
									<TableCell component="th" scope="row">
										Month {n.month_number}
									</TableCell>
									<TableCell component="th" scope="row" className={!n.is_default ? "light-color" : ""}>
										Is Default:{n.is_default}
									</TableCell> */}

                    {/* <TableCell className="truncate" component="th" scope="row">
										<IconButton
											onClick={ev => {
												ev.stopPropagation();
												// dispatch(Actions.deleteIndicator(n.indicator_id));
											}}
										>
											<DeleteIcon />
										</IconButton>
									</TableCell> */}
                  </TableRow>
                );
              })}
        </TableBody>
      </Table>
      <TablePagination
        className="overflow-hidden"
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 20, 50, { value: data.length, label: 'All' }]}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
}

export default withRouter(AgentSummaryTable);
