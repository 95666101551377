import _ from 'lodash';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as Actions from './store/actions';
import UsersTableHead from './UsersTableHead';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import '../AgentSummary/agentSummary.css';

function UsersTable(props) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const userList = useSelector(
    ({ adminPortal }) => adminPortal.agentPortal.userList
  );
  const searchText = useSelector(
    ({ adminPortal }) => adminPortal.agentPortal.searchText
  );

  console.log(userList);

  const [selected, setSelected] = useState([]);
  const [data, setData] = useState(userList);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const open = Boolean(anchorEl);
  const [order, setOrder] = useState({
    direction: 'asc',
    id: null,
  });

  useEffect(() => {
    dispatch(Actions.getUsersList());
  }, [dispatch]);

  useEffect(() => {
    if (searchText.length !== 0) {
      setData(
        _.filter(
          userList,
          item =>
            item.first_name.toLowerCase().includes(searchText.toLowerCase()) ||
            item.last_name.toLowerCase().includes(searchText.toLowerCase()) ||
            item.email.toLowerCase().includes(searchText.toLowerCase())
        )
      );
      setPage(0);
    } else {
      setData(userList);
    }
  }, [userList, searchText]);

  function handleRequestSort(event, property) {
    const id = property;
    let direction = 'desc';

    if (order.id === property && order.direction === 'desc') {
      direction = 'asc';
    }

    setOrder({
      direction,
      id,
    });
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      setSelected(data.map(n => n.id));
      return;
    }
    setSelected([]);
  }

  function handleClick(item) {
    if (!anchorEl) dispatch(Actions.openEditUsersDialog(item));
  }

  const handleMoreClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    dispatch(Actions.closeEditIndicatorDialog());
  };

  function handleCheck(event, id) {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  }

  function handleChangePage(event, value) {
    setPage(value);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
  }

  if (data.length === 0) {
    return (
      <div className="flex flex-1 text-center justify-center h-full">
        <Typography color="textSecondary" variant="h5">
          No records found for matching filter criteria!
        </Typography>
      </div>
    );
  }

  return (
    <div
      className="w-full flex flex-col"
      style={{ marginLeft: '3em', marginRight: '3em' }}
    >
      <Table component={Paper} aria-labelledby="tableTitle">
        <UsersTableHead
          numSelected={selected.length}
          order={order}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={data.length}
        />

        <TableBody>
          {data.length > 0 &&
            _.orderBy(
              data,
              [
                o => {
                  switch (order.id) {
                    case 'categories': {
                      return o.categories[0];
                    }
                    default: {
                      return o[order.id];
                    }
                  }
                },
              ],
              [order.direction]
            )
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(n => {
                const isSelected = selected.indexOf(n.id) !== -1;
                return (
                  <TableRow
                    style={{ cursor: 'pointer' }}
                    hover
                    role="checkbox"
                    aria-checked={isSelected}
                    tabIndex={-1}
                    key={n.id}
                    selected={isSelected}
                    onClick={event => handleClick(n)}
                  >
                    <TableCell component="th" scope="row">
                      {n.first_name + ' ' + n.last_name}
                    </TableCell>

                    <TableCell component="th" scope="row">
                      {n.email}
                    </TableCell>

                    <TableCell component="th" scope="row">
                      {n.is_superuser ? (
                        <Chip className="chip" label="Superuser" />
                      ) : null}
                      {n.is_manager ? (
                        <Chip className="chip" label="Manager" />
                      ) : null}
                      {n.is_active ? (
                        <Chip className="chip" label="Active" />
                      ) : null}
                    </TableCell>

                    {/* <TableCell className="truncate" component="th" scope="row">
										<IconButton
											aria-label="more"
											aria-controls="long-menu"
											aria-haspopup="true"
											onClick={(event) => {
												handleMoreClick(event);
												event.stopPropagation();
											}}
										>
											<MoreVertIcon />
										</IconButton>
										<Menu
											id="long-menu"
											anchorEl={anchorEl}
											keepMounted
											open={open}
											onClose={handleClose}
										>
											<MenuItem disabled key={0}>
												Edit
                                        	</MenuItem>
											<MenuItem key={1} onClick={handleClose}>
												Assign Role
                                            </MenuItem>
											<MenuItem key={2} onClick={handleClose}>
												Reset Password
                                            </MenuItem>
											<MenuItem key={3} onClick={handleClose}>
												Deactivate
                                            </MenuItem>
										</Menu>
									</TableCell> */}
                  </TableRow>
                );
              })}
        </TableBody>
      </Table>
      <TablePagination
        className="overflow-hidden"
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 15, { value: data.length, label: 'All' }]}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
}

export default withRouter(UsersTable);
