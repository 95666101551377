import _ from 'lodash';
import Checkbox from '@material-ui/core/Checkbox';
import Icon from '@material-ui/core/Icon';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as Actions from '../store/actions';
import SuceessPointsTableHead from './SuceessPointsTableHead';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import Paper from '@material-ui/core/Paper';

function SuccessPointsTable(props) {
  const dispatch = useDispatch();
  const successPointsList = useSelector(
    ({ adminPortal }) => adminPortal.agentPortal.successPointsList
  );
  const searchText = useSelector(
    ({ adminPortal }) => adminPortal.agentPortal.searchText
  );

  const [selected, setSelected] = useState([]);
  const [data, setData] = useState(successPointsList);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState({
    direction: 'asc',
    id: null,
  });

  useEffect(() => {
    dispatch(Actions.getSuccessPointsList());
  }, [dispatch]);

  useEffect(() => {
    if (searchText.length !== 0) {
      setData(
        _.filter(successPointsList, item =>
          item.label.toLowerCase().includes(searchText.toLowerCase())
        )
      );
      setPage(0);
    } else {
      setData(successPointsList);
    }
  }, [successPointsList, searchText]);

  function handleRequestSort(event, property) {
    const id = property;
    let direction = 'desc';

    if (order.id === property && order.direction === 'desc') {
      direction = 'asc';
    }

    setOrder({
      direction,
      id,
    });
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      setSelected(data.map(n => n.id));
      return;
    }
    setSelected([]);
  }

  function handleClick(item) {
    dispatch(Actions.openEditSuccessPointDialog(item));
  }

  function handleCheck(event, id) {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  }

  function handleChangePage(event, value) {
    setPage(value);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
  }

  if (data.length === 0) {
    return (
      <div className="flex flex-1 items-center justify-center h-full">
        <Typography color="textSecondary" variant="h5">
          No records found for matching filter criteria!
        </Typography>
      </div>
    );
  }

  const kFormatter = num => {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'k'
      : Math.sign(num) * Math.abs(num);
  };

  return (
    <div
      className="w-full flex flex-col"
      style={{ marginLeft: '3em', marginRight: '3em' }}
    >
      <Table component={Paper} aria-labelledby="tableTitle">
        <SuceessPointsTableHead
          numSelected={selected.length}
          order={order}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={data.length}
        />

        <TableBody>
          {data.length > 0 &&
            _.orderBy(
              data,
              [
                o => {
                  switch (order.id) {
                    case 'categories': {
                      return o.categories[0];
                    }
                    default: {
                      return o[order.id];
                    }
                  }
                },
              ],
              [order.direction]
            )
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(n => {
                const isSelected = selected.indexOf(n.id) !== -1;
                return (
                  <TableRow
                    style={{ cursor: 'pointer' }}
                    hover
                    role="checkbox"
                    aria-checked={isSelected}
                    tabIndex={-1}
                    key={n.id}
                    selected={isSelected}
                    onClick={event => handleClick(n)}
                  >
                    <TableCell component="th" scope="row">
                      {n.sp_name}
                    </TableCell>

                    <TableCell component="th" scope="row">
                      {n.amount_greater ? 'Greater than' : 'Less than'}
                    </TableCell>

                    <TableCell component="th" scope="row">
                      {kFormatter(n.amount)}
                    </TableCell>

                    <TableCell component="th" scope="row">
                      {n.years}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {n.success_type}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {n.consecutive_years}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {n.weight}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {(Math.round(n.base_average * 100) / 100).toFixed(2)}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {n.metric}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className={!n.is_default ? 'light-color' : ''}
                    >
                      Is Default:{n.is_default}
                    </TableCell>

                    <TableCell className="truncate" component="th" scope="row">
                      <IconButton
                        onClick={ev => {
                          ev.stopPropagation();
                          dispatch(Actions.deleteSuccessPoint(n.sp_id));
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
        </TableBody>
      </Table>
      <TablePagination
        className="overflow-hidden"
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 15, { value: data.length, label: 'All' }]}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
}

export default withRouter(SuccessPointsTable);
