import Checkbox from '@material-ui/core/Checkbox';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import clsx from 'clsx';
import React, { useState } from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import DeleteIcon from '@material-ui/icons/Delete';

export const ROW_IDS = {
  MONTH: 'monthNumber',
  APPS_RECEIVED: 'appsReceived',
  APP_SUBMITTED: 'appsSubmitted',
  JOINED_PERCENTAGE: 'joinedPercentage',
  APPS_PLACED: 'appsPlaced',
  RECEIVED_TO_SUBMITTED_RATION: 'receivedToSubmittedRatio',
  FYC: 'fyc',
  LIVES: 'lives',
};

const rows = [
  {
    id: ROW_IDS.MONTH,
    align: 'left',
    disablePadding: false,
    label: 'Month',
    sort: true,
  },
  {
    id: ROW_IDS.APPS_RECEIVED,
    align: 'left',
    disablePadding: false,
    label: 'Total Apps Received',
    sort: true,
  },
  {
    id: ROW_IDS.APP_SUBMITTED,
    align: 'left',
    disablePadding: false,
    label: 'Apps Submitted',
    sort: true,
  },
  {
    id: ROW_IDS.JOINED_PERCENTAGE,
    align: 'left',
    disablePadding: false,
    label: 'Joined %',
    sort: true,
  },
  {
    id: ROW_IDS.APPS_PLACED,
    align: 'left',
    disablePadding: false,
    label: 'Apps Placed',
    sort: true,
  },
  {
    id: ROW_IDS.RECEIVED_TO_SUBMITTED_RATION,
    align: 'left',
    disablePadding: false,
    label: 'Received to Submitted Ratio',
    sort: true,
  },
  // {
  // 	id: 'month_number',
  // 	align: 'left',
  // 	disablePadding: false,
  // 	label: 'Submitted to Placed Ratio',
  // 	sort: true
  // },
  // {
  // 	id: 'month_number',
  // 	align: 'left',
  // 	disablePadding: false,
  // 	label: 'Consistency',
  // 	sort: true
  // },
  {
    id: ROW_IDS.FYC,
    align: 'left',
    disablePadding: false,
    label: 'FYC',
    sort: true,
  },
  {
    id: ROW_IDS.LIVES,
    align: 'left',
    disablePadding: false,
    label: 'Lives',
    sort: true,
  },
  // {
  // 	id: 'month_number',
  // 	align: 'left',
  // 	disablePadding: false,
  // 	label: 'Protection',
  // 	sort: true
  // },
  // {
  // 	id: 'month_number',
  // 	align: 'left',
  // 	disablePadding: false,
  // 	label: 'Premium',
  // 	sort: true
  // },
  // {
  // 	id: 'month_number',
  // 	align: 'left',
  // 	disablePadding: false,
  // 	label: 'Success Average',
  // 	sort: true
  // },
];

const useStyles = makeStyles((theme) => ({
  actionsButtonWrapper: {
    background: theme.palette.background.paper,
  },
}));

function IndicatorsTableHead(props) {
  const classes = useStyles(props);
  const [selectedClassMenu, setSelectedClassMenu] = useState(null);

  const createSortHandler = (property) => (event) => {
    props.onRequestSort(event, property);
  };

  function openSelectedClassMenu(event) {
    setSelectedClassMenu(event.currentTarget);
  }

  function closeSelectedClassMenu() {
    setSelectedClassMenu(null);
  }

  // Check values for FYC and Lives
  let showHeaders = [...rows];
  if (!props.hasAtLeastOneFYC) {
    showHeaders = showHeaders.filter((row) => row.id !== ROW_IDS.FYC);
  }

  if (!props.hasAtLeastOneLives) {
    showHeaders = showHeaders.filter((row) => row.id !== ROW_IDS.LIVES);
  }

  return (
    <TableHead>
      <TableRow className="h-64">
        {showHeaders.map((row) => {
          return (
            <TableCell
              style={{ fontWeight: 'bold' }}
              key={row.id}
              align={row.align}
              padding={row.disablePadding ? 'none' : 'default'}
              sortDirection={
                props.order.id === row.id ? props.order.direction : false
              }
            >
              {row.sort && (
                <Tooltip
                  title="Sort"
                  placement={
                    row.align === 'right' ? 'bottom-end' : 'bottom-start'
                  }
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={props.order.id === row.id}
                    direction={props.order.direction}
                    onClick={createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              )}
            </TableCell>
          );
        }, this)}
      </TableRow>
    </TableHead>
  );
}

export default IndicatorsTableHead;
