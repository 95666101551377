import React, { useEffect, useState } from 'react';
import backIcon from '../../assets/left-arrow.png';
import settingSmallIcon from '../../assets/settings-small.png';
import Header from '../Header';
import SideNav from '../SideBar';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from './store/actions';
import * as AgentActions from '../AgentSummary/store/actions';
import reducer from './store/reducers';
import withReducer from '../../store/withReducer';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import SplashScreen from '../SplashScreen';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import commaNumber from 'comma-number';
import _ from 'lodash';
import RowDataTableHead, { ROW_IDS } from './rowDataTableHead';

import './rowData.css';
import LoadingScreen from '../LoadingScreen';

const INDICATOR_TYPES = {
  APPLICATION_RECEIVED: 'Applications Received',
  APPLICATIONS_PLACED: 'Applications Placed',
  APPLICATIONS_SUBMITTED: 'Applications Submitted',
  APPLICATIONS_SUBMITTED_PERCENT: 'Applications Submitted Percent',
  JOINT_PERCENT: 'Joint Percent',
  FYC: 'FYC',
  Lives: 'Lives',
};

const getIndividualRawData = (monthData, options) => {
  const getValue = (indicatorType) =>
    monthData.indicators.find((i) => i.indicator_type === indicatorType) || {};
  const individualData = {
    [ROW_IDS.APPS_RECEIVED]: Math.round(
      getValue(INDICATOR_TYPES.APPLICATION_RECEIVED)[options.dataFilterValue]
    ),
    [ROW_IDS.APP_SUBMITTED]: Math.round(
      getValue(INDICATOR_TYPES.APPLICATIONS_SUBMITTED)[options.dataFilterValue]
    ),
    [ROW_IDS.JOINED_PERCENTAGE]: Math.round(
      getValue(INDICATOR_TYPES.JOINT_PERCENT)[options.dataFilterValue] * 100
    ),
    [ROW_IDS.APPS_PLACED]: Math.round(
      getValue(INDICATOR_TYPES.APPLICATIONS_PLACED)[options.dataFilterValue]
    ),
    // placedRatio: "14%",
    [ROW_IDS.RECEIVED_TO_SUBMITTED_RATION]: Math.round(
      getValue(INDICATOR_TYPES.APPLICATIONS_SUBMITTED_PERCENT)[
        options.dataFilterValue
      ] * 100
    ),
    [ROW_IDS.FYC]: getValue(INDICATOR_TYPES.FYC)[options.dataFilterValue],
    [ROW_IDS.LIVES]: getValue(INDICATOR_TYPES.Lives)[options.dataFilterValue],
    // consistency: 6,
    // protection: "1,284,000",
    // premium: "$74,000",
    // average: "44/100",
    // averageValue: 44
    // fycColor: "row-bg-danger",
    // livesColor: "row-bg-danger",
    // protectionColor: "row-bg-success",
    // premiumColor: "row-bg-success"
  };
  individualData.invalid = Object.keys(individualData).every((key) =>
    isNaN(individualData[key])
  );
  individualData[ROW_IDS.MONTH] = monthData.month_number + 1;

  return individualData;
};

const RowData = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { agentId } = useParams();

  // Filters
  const [dataFilterValue, setDataFilterValue] = useState('data_value');
  const [yearFilterValue, setYearFilter] = useState();

  // Table Data
  const rowData = useSelector(({ rowData }) => rowData.rawDataReducer.data);

  // In table loader
  const isLoading = useSelector(
    ({ rowData }) => rowData.rawDataReducer.isLoading
  );
  const agentSummary = useSelector(
    ({ agentSummary }) => agentSummary.agentSummary.agentSummary
  );

  // Pagination and sorting
  const [order, setOrder] = useState({
    direction: 'asc',
    id: null,
  });

  // API call for data
  useEffect(() => {
    if (agentId) {
      dispatch(Actions.startLoader());
      dispatch(Actions.getRawData({ agentId: agentId }));
    }
  }, [agentId, dispatch, history.location.pathname]);

  useEffect(() => {
    dispatch(AgentActions.getAgentList());
  }, [dispatch]);

  // Loader screen
  if (!rowData) {
    return <SplashScreen />;
  }

  // Data filter and preparation for table
  let data = rowData
    .filter((d) =>
      yearFilterValue ? d.month_number === parseInt(yearFilterValue) : true
    )
    .map((d) => getIndividualRawData(d, { dataFilterValue }))
    .filter((d) => !d.invalid);

  const handleIndicatorValueChange = (event) => {
    setDataFilterValue(event.target.value);
  };

  const handleAgentValueChange = (event, newInputValue) => {
    const getAgentValue = newInputValue.split(' ');
    // Redirect user for data
    const getAgentID = getAgentValue[1] || '';
    history.push({
      pathname: `/row-data/${getAgentID}`,
    });
  };

  // Sorting function
  function handleRequestSort(event, property) {
    const id = property;
    let direction = 'desc';

    if (order.id === property && order.direction === 'desc') {
      direction = 'asc';
    }

    setOrder({
      direction,
      id,
    });
  }

  const hasData = (key, currentRow) => {
    return (
      currentRow[key] && !isNaN(currentRow[key]) && currentRow[key] !== 'NaN'
    );
  };

  /**
   * Check for FYC and Lives fields
   */
  const hasAtLeastOneFYC = (data || []).some((row) =>
    hasData(ROW_IDS.FYC, row)
  );
  const hasAtLeastOneLives = (data || []).some((row) =>
    hasData(ROW_IDS.LIVES, row)
  );

  // Update data for non existing keys
  data = data.map((d) => {
    if (!hasAtLeastOneFYC) {
      delete d[ROW_IDS.FYC];
    }
    if (!hasAtLeastOneLives) {
      delete d[ROW_IDS.LIVES];
    }
    return d;
  });

  // Update fields to display
  let updatedRowIds = { ...ROW_IDS };
  if (!hasAtLeastOneFYC) {
    delete updatedRowIds.FYC;
  }
  if (!hasAtLeastOneLives) {
    delete updatedRowIds.LIVES;
  }

  const getCommaNumber = (key, n) => {
    try {
      return key === ROW_IDS.FYC ? commaNumber(n[key]) : n[key];
    } catch (error) {
      return n[key];
    }
  };

  return (
    <>
      <div>
        <Header />
      </div>

      <div>
        <SideNav />
      </div>
      <div className="mainPortion">
        <div className="upperHeader">
          <span className="bottomBorder">
            <span>
              <Link className="tabText" to="/agent-success-predictions">
                Agent Summary
              </Link>
            </span>
          </span>{' '}
          &nbsp;
          <span className="bottomBorder activeTab">
            <span className="tabText">Agent Details</span>
          </span>
          <span>
            <span className="adminPortalRow">
              <img
                src={settingSmallIcon}
                alt="settingIcon"
                className="settingIcon"
              />
              <span onClick={() => history.push('/admin-portal/users')}>
                Admin Portal
              </span>
            </span>
            {/* <button className="btn newBtn">Export</button> */}
          </span>
        </div>

        <div className="agentDetails">
          <div style={{ marginBottom: '10px' }}>
            <span className="heading">
              <span className="backArrow">
                <Link to={`/agent-details/${agentId}`}>
                  <img src={backIcon} alt="backArrowIcon" />
                </Link>
              </span>
              {/* <FontAwesomeIcon className="backArrow" icon={faArrowLeft} /> */}
              <span style={{ marginTop: '-10' }}>
                Agent Details/Row Data/
                {agentSummary &&
                  (
                    agentSummary.find((a) => {
                      return a.agent_id === parseInt(agentId || 0);
                    }) || {}
                  ).agent_name}
              </span>
            </span>

            <span className="filter">
              <select
                className="option mr1"
                onChange={handleIndicatorValueChange}
                value={dataFilterValue}
              >
                <option value="data_value">Data Value</option>
                <option value="history_sum">History Sum</option>
                <option value="history_avg">History Average</option>
                <option value="rate_of_change">Rate of Change</option>
                <option value="avg_rate_of_change">
                  Average Rate of Change
                </option>
              </select>
              <span className="mr1">Month in business</span>
              <FormControl className="mr1">
                <TextField
                  type="number"
                  label="Year"
                  name="year"
                  variant="outlined"
                  size="small"
                  onChange={(ev) => setYearFilter(ev.target.value)}
                />
              </FormControl>
              <FormControl className="search-container">
                <Autocomplete
                  id="combo-box-demo"
                  options={agentSummary}
                  getOptionLabel={(option) => option.agent_name}
                  style={{ width: 300 }}
                  onInputChange={(event, newInputValue) =>
                    handleAgentValueChange(event, newInputValue)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search agents"
                      size="small"
                      variant="outlined"
                      onChange={(ev) => {
                        console.log('onChange', ev.target.value);
                      }}
                    />
                  )}
                />
              </FormControl>
            </span>
          </div>
          <br />
          {isLoading ? (
            <LoadingScreen></LoadingScreen>
          ) : data.length === 0 ? (
            <div className="flex flex-1 text-center justify-center h-full">
              <Typography color="textSecondary" variant="h5">
                No records found for matching filter criteria!
              </Typography>
            </div>
          ) : (
            <div className="rowDataTable">
              <TableContainer component={Paper}>
                <Table aria-label="a dense table">
                  <RowDataTableHead
                    order={order}
                    onRequestSort={handleRequestSort}
                    rowCount={data.length}
                    hasAtLeastOneFYC={hasAtLeastOneFYC}
                    hasAtLeastOneLives={hasAtLeastOneLives}
                  />

                  <TableBody>
                    {data.length > 0 &&
                      _.orderBy(data, order.id, order.direction).map((n) => {
                        return (
                          <TableRow
                            style={{ cursor: 'pointer' }}
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={n.id}
                          >
                            {Object.values(updatedRowIds).map((key) => {
                              return (
                                <TableCell scope="row">
                                  {isNaN(n[key])
                                    ? '-'
                                    : `${getCommaNumber(key, n)}${
                                        key === updatedRowIds.JOINED_PERCENTAGE
                                          ? '%'
                                          : ''
                                      }`}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </div>
        <br />
      </div>
    </>
  );
};

export default withReducer('rowData', reducer)(RowData);
