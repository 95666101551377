import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { Link } from 'react-router-dom';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import withReducer from '../../store/withReducer';
import * as Actions from './store/actions';
import * as AdminActions from '../AdminPortal/store/actions';
import './agentSummary.css';

import reducer from './store/reducers';
import AgentSummaryTable from './AgentSummaryTable';
import LoadingScreen from '../LoadingScreen';
import SplashScreen from '../SplashScreen';
import * as authAction from '../Login/store/actions';

const AgentSummary = () => {
  const dispatch = useDispatch();

  const agentSummary = useSelector(
    (store) => store.agentSummary.agentSummary.agentSummary
  );

  const searchText = useSelector(
    (store) => store?.agentSummary?.agentSummary?.searchText
  );

  const matchedUser = agentSummary?.find((a) => a.agent_name === searchText);

  const successPointsList = useSelector(
    ({ adminPortal }) => adminPortal.agentPortal.successPointsList
  );

  const [waitAuthcheck, setWaitAuthCheck] = useState(true);

  useEffect(() => {
    dispatch(authAction.signInWithCookie()).then(() => {
      setWaitAuthCheck(false);
      dispatch(Actions.getAgentList());
      // Get Success Point list for column headers
      dispatch(AdminActions.getSuccessPointsList());
    });
  }, [dispatch]);

  // Auth check splash screen
  if (waitAuthcheck || !Array.isArray(agentSummary)) {
    return <SplashScreen />;
  }

  return (
    <>
      {/*
            <div><Header /></div>

            <div><SideNav /></div> */}
      <div className="mainPortion">
        <div className="upperHeader">
          <span className="bottomBorder activeTab">
            <span className="tabText">Agent Summary</span>
          </span>
          &nbsp;
          <span className="bottomBorder">
            <span>
              <Link className="tabText" to="/agent-details">
                Agent Details
              </Link>
            </span>
          </span>
          {/* <span>
            <button className="btn newBtn">New</button>
          </span> */}
        </div>
        <div className="agentSummary">
          <div style={{ 'margin-bottom': '10px' }}>
            <span className="heading">Agent Summary</span>
            <span className="filter">
              Year in business
              {/* <select className="option">
                                <option>4 years</option>
                                <option>5 years</option>
                            </select> */}
              {/* <FormControl style={{marginRight:'5px', marginLeft:'5px', marginTop:'-15px'}}>
                            <Autocomplete
                                id="highlights-demo"
                                style={{ width: 150 }}
                                options={agentSummary}
                                getOptionLabel={(option) => option.agent_name}
                                renderInput={(params) => (
                                    <TextField {...params} label="Year" variant="outlined" margin="normal" size="small" />
                                )}
                                renderOption={(option, { inputValue }) => {
                                    const matches = match(option.agent_name, inputValue);
                                    const parts = parse(option.agent_name, matches);

                                    return (
                                    <div>
                                        {parts.map((part, index) => (
                                        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                            {part.text}
                                        </span>
                                        ))}
                                    </div>
                                    );
                                }}
                            />
                            </FormControl> */}
              <FormControl style={{ margin: '5px' }}>
                <TextField
                  type="number"
                  label="Year"
                  name="year"
                  variant="outlined"
                  size="small"
                  onChange={(ev) =>
                    dispatch(Actions.setAgentYearText(ev.target.value))
                  }
                />
              </FormControl>
              <FormControl
                className="search-container"
                style={{ marginRight: '2px', marginTop: '-15px' }}
              >
                <Autocomplete
                  id="highlights-demo"
                  className="searchBox"
                  style={{ width: 300 }}
                  options={agentSummary}
                  getOptionLabel={(option) => option.agent_name}
                  onInputChange={(event, newInputValue) => {
                    if (event?.type !== 'blur') {
                      dispatch(Actions.setAgentSearchText(newInputValue));
                    }
                  }}
                  value={matchedUser ?? undefined}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search agents"
                      variant="outlined"
                      margin="normal"
                      size="small"
                      onChange={(ev) => {
                        dispatch(Actions.setAgentSearchText(ev.target.value));
                      }}
                    />
                  )}
                  renderOption={(option, { inputValue }) => {
                    const matches = match(option.agent_name, inputValue);
                    const parts = parse(option.agent_name, matches);

                    return (
                      <div>
                        {parts.map((part, index) => (
                          <span
                            key={index}
                            style={{ fontWeight: part.highlight ? 700 : 400 }}
                          >
                            {part.text}
                          </span>
                        ))}
                      </div>
                    );
                  }}
                />
              </FormControl>
              {/* <span className="search-container">
                                <input
                                className="searchBox"
                                id="search-bar"
                                type="text"
                                placeholder="Search agents"
                                onChange={ev => dispatch(Actions.setAgentSearchText(ev))}
                                />
                                <FontAwesomeIcon className="search-icon" style={{ 'color': '#64bb46' }} icon={faSearch} />
                            </span> */}
            </span>
          </div>
          <br />
          <br />
          {/* <div className="column">
                        <span className="tableHead">
                            <span>Agent Name <FontAwesomeIcon style={{ 'color': '#aaa' }} icon={faCaretDown} /></span>
                            <span style={{ 'margin-left': '12%' }}>Month in Business <FontAwesomeIcon style={{ 'color': '#aaa' }} icon={faCaretDown} /></span>
                            <span style={{ 'margin-left': '12%' }}>Success Points <FontAwesomeIcon style={{ 'color': '#aaa' }} icon={faCaretDown} /></span>
                            <span style={{ 'margin-left': '2%' }}>50k <FontAwesomeIcon style={{ 'color': '#aaa' }} icon={faCaretDown} /></span>
                            <span style={{ 'margin-left': '2%' }}>100k <FontAwesomeIcon style={{ 'color': '#aaa' }} icon={faCaretDown} /></span>
                            <span style={{ 'margin-left': '2%' }}>200k <FontAwesomeIcon style={{ 'color': '#aaa' }} icon={faCaretDown} /></span>
                            <span style={{ 'margin-left': '2%' }}>300k <FontAwesomeIcon style={{ 'color': '#aaa' }} icon={faCaretDown} /></span>
                            <span style={{ 'margin-left': '2%' }}>600k <FontAwesomeIcon style={{ 'color': '#aaa' }} icon={faCaretDown} /></span>
                            <span style={{ 'margin-left': '2%' }}>Centurion <FontAwesomeIcon style={{ 'color': '#aaa' }} icon={faCaretDown} /></span>
                        </span>
                    </div> */}
          {/* <div>
                    <Link to="/agent-details">
                        {
                            agentSummary && agentSummary.map(raw =>(
                                <Card className="card" key={raw.agent_id}>
                                    <span className="td">
                                        <label className="container chk-box">
                                            <input type="checkbox" />
                                            <span className="checkmark"></span>
                                        </label>
                                    </span>

                                    <div className="agentName spacingCard">{raw.agent_name}</div>
                                    <div className="agentMonth spacingCard">{raw.months_in_business} months</div>
                                    {
                                        raw.success_points && raw.success_points.map(sp => (
                                            <>
                                            <div className="agentPoints spacingCardPoints">{sp.likelihood.toFixed(2)}</div>
                                                <div className="successPoints spacingCardPoints" key={sp.sp_id}>
                                                    <div>{sp.sp_name}<br /><span style={{ 'font-size': '10px' }}>
                                                        <span className="bold">{sp.score.toFixed(2)}</span>/100</span>
                                                        <ProgressBar progress={sp.score.toFixed(2)} />
                                                </div></div>
                                            </>
                                        ))
                                    }
                                </Card>
                            ))
                        }
                        </Link>
                    </div> */}
        </div>
        {!agentSummary || !successPointsList ? (
          <LoadingScreen />
        ) : (
          <AgentSummaryTable successPointsList={successPointsList} />
        )}
      </div>
    </>
  );
};

export default withReducer('agentSummary', reducer)(AgentSummary);
