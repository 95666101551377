import axios from '../../../../configs/axiosConfig';
import { history } from '../../../../App';
import * as Actions from '../../../SnackbarMessage/store/actions';

export const INDICATORS_LIST = '[ADMIN PORTAL] INDICATORS LIST';
export const CREATE_INDICATOR = '[ADMIN PORTAL] CREATE INDICATOR';
export const UPDATE_INDICATOR = '[ADMIN PORTAL] UPDATE INDICATOR';
export const DELETE_INDICATOR = '[ADMIN PORTAL] DELETE INDICATOR';
export const OPEN_NEW_INDICATOR_DIALOG =
  '[ADMIN PORTAL] OPEN NEW INDICATOR DIALOG';
export const CLOSE_NEW_INDICATOR_DIALOG =
  '[ADMIN PORTAL] CLOSE NEW INDICATOR DIALOG';
export const OPEN_EDIT_INDICATOR_DIALOG =
  '[ADMIN PORTAL] OPEN EDIT INDICATOR DIALOG';
export const CLOSE_EDIT_INDICATOR_DIALOG =
  '[ADMIN PORTAL] CLOSE EDIT INDICATOR DIALOG';

axios.defaults.withCredentials = true;

export function getIndicatorsList() {
  const request = axios.get('/indicator/list');

  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: INDICATORS_LIST,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
        history.push('/login');
      });
}

export function refreshIndicatorsList() {
  const request = axios.get('/indicator/refresh');

  return (dispatch) => {
    dispatch({
      type: INDICATORS_LIST,
      payload: null,
    });
    return request
      .then(() => {
        dispatch(
          Actions.showMessage({
            message: 'Refreshed Indicators',
            variant: 'success',
          })
        );
        getIndicatorsList()(dispatch);
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function createIndicator(data) {
  const request = axios.post('/indicator/create', data);

  return (dispatch) =>
    request.then((response) => {
      Promise.all([
        dispatch({
          type: CREATE_INDICATOR,
        }),
      ]).then(() => dispatch(getIndicatorsList()));
    });
}

export function updateIndicator(data) {
  const request = axios.put('/indicator/update', data);

  return (dispatch) =>
    request.then((response) => {
      // dispatch({
      //     type: UPDATE_INDICATOR,
      //     payload: response.data
      // });
      Promise.all([
        dispatch({
          type: UPDATE_INDICATOR,
        }),
      ]).then(() => dispatch(getIndicatorsList()));
    });
}

export function deleteIndicator(id) {
  const request = axios.delete('/indicator/delete/' + id);

  return (dispatch) =>
    request.then((response) => {
      // dispatch({
      //     type: DELETE_INDICATOR
      // });
      Promise.all([
        dispatch({
          type: DELETE_INDICATOR,
        }),
      ]).then(() => dispatch(getIndicatorsList()));
    });
}

export function openIndicatorDialog() {
  return (dispatch) => {
    dispatch({
      type: OPEN_NEW_INDICATOR_DIALOG,
    });
  };
}

export function closeIndicatorDialog() {
  return (dispatch) => {
    dispatch({
      type: CLOSE_NEW_INDICATOR_DIALOG,
    });
  };
}

export function openEditIndicatorDialog(data) {
  return (dispatch) => {
    dispatch({
      type: OPEN_EDIT_INDICATOR_DIALOG,
      data,
    });
  };
}

export function closeEditIndicatorDialog() {
  return (dispatch) => {
    dispatch({
      type: CLOSE_EDIT_INDICATOR_DIALOG,
    });
  };
}
